body {
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}
a, a:visited {
  color: #22b52f;
}
ul {
  padding-left: 1rem;
}
.App {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  row-gap: 0.5rem;
}
.CV {
  max-width: 600px;
  padding: 0 1rem;
}
.Job-blurb {
  margin-bottom: 2rem;
}

.Job-title {
  font-weight: bold;
}

.Project {
  margin-bottom: 3rem;
  margin-left: 1rem;
}
a.Project-name {
  font-weight: bold;
  color: black;
  display: inline-block;
  margin-bottom: calc(1rem);
}
.Project-desc-lead {
  font-style: italic;
  margin-bottom: calc(0.5rem);
}
.Project-desc {
  margin-left: 1rem;
  margin-bottom: calc(1rem);
  white-space: pre-line;
}
.Project-impact-lead {
  font-style: italic;
  margin-bottom: calc(0.5rem);
}
.Project-impact {
  margin-left: 1rem;
  margin-bottom: calc(1rem);
  white-space: pre-line;
}
.Project-tech{
}

.Footer {
  margin-left: 1rem;
  margin-bottom: 3rem;
}